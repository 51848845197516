import React from 'react';
import './upgrade.css';

const UpgradePage = () => {
    return (
        <div className="upgrade-page">
            <h1>进阶技能</h1>
            <p>这是 Python 学习的进阶技能页面。</p>
        </div>
    );
};

export default UpgradePage;
