import React, { useState, useEffect } from 'react';
import { Menu, Form, Input, Button, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './SettingsPage.css';

const SettingsPage = ({ isLoggedIn, apiKey, setApiKey, userName }) => {
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (isLoggedIn && apiKey) {
            setApiKey(apiKey);
        }
    }, [isLoggedIn, apiKey, setApiKey]);

    const handleEditClick = () => {
        if (!isLoggedIn) {
            message.warning('请登录后再编辑');
            return;
        }
        setIsEditing(true);
    };

    const handleSaveClick = async () => {
        if (await saveApiKey(apiKey, userName)) {
            setIsEditing(false);
            message.success('API key 已保存');
        }
    };

    const saveApiKey = async (apiKey, userName) => {
        const token = sessionStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const url = `${apiUrl}/update_key`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_name: userName, api_key: apiKey }), // 传递用户名称
            });

            const data = await response.json();

            if (response.ok && data.status === 200) {
                return true;
            } else {
                message.error(data.message);
                return false;
            }
        } catch (error) {
            message.error('网络异常，检查后重试！');
            return false;
        }
    };

    return (
        <div>
            <Menu mode="horizontal" defaultSelectedKeys={['personalSettings']} className="settings-menu">
                <Menu.Item key="personalSettings">个人设置</Menu.Item>
            </Menu>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="horizontal" style={{ marginTop: '20px' }}>
                <Form.Item label="API key">
                    <Input.Password
                        value={apiKey}
                        onChange={(e) => setApiKey(e.target.value)}
                        disabled={!isLoggedIn || !isEditing}
                        visibilityToggle={isEditing}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 14, offset: 4 }}>
                    {!isEditing ? (
                        <Button type="primary" onClick={handleEditClick} className="edit-button">
                            编辑
                        </Button>
                    ) : (
                        <Button type="primary" onClick={handleSaveClick}>
                            保存
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    );
};

export default SettingsPage;
