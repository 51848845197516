import React from 'react';
import './apply.css';

const ApplyPage = () => {
    return (
        <div className="apply-page">
            <h1>应用领域</h1>
            <p>这是 Python 学习的应用领域页面。</p>
        </div>
    );
};

export default ApplyPage;
