import React from 'react';
import './TipNotification.css';

const TipNotification = ({ message, onClick }) => {
    return (
        <div className="tip-notification" onClick={onClick}>
            {message}
        </div>
    );
};

export default TipNotification;
