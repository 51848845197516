// Captcha.js文件
import React, { useEffect, useRef, useState } from 'react';

const generateRandomCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
};

const drawWaveLine = (ctx, canvasWidth, canvasHeight) => {
    ctx.beginPath();
    ctx.moveTo(0, canvasHeight / 2);
    ctx.bezierCurveTo(
        canvasWidth / 4, canvasHeight / 4,
        (3 * canvasWidth) / 4, (3 * canvasHeight) / 4,
        canvasWidth, canvasHeight / 2
    );
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
    ctx.lineWidth = 2;
    ctx.stroke();
};

const Captcha = ({ onChange }) => {
    const [captcha, setCaptcha] = useState(generateRandomCode());
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw background
        ctx.fillStyle = '#f3f3f3';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw text
        ctx.font = '20px Arial';
        ctx.fillStyle = '#000';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(captcha, canvas.width / 2, canvas.height / 2);

        // Draw wave lines
        drawWaveLine(ctx, canvas.width, canvas.height);
        drawWaveLine(ctx, canvas.width, canvas.height);

        // Apply blur effect
        ctx.globalAlpha = 0.8;
        ctx.filter = 'blur(1px)';
        ctx.drawImage(canvas, 0, 0);

        onChange(captcha);
    }, [captcha, onChange]);

    const refreshCaptcha = () => {
        setCaptcha(generateRandomCode());
    };

    return (
        <div onClick={refreshCaptcha} style={{ cursor: 'pointer' }}>
            <canvas ref={canvasRef} width="100" height="40" />
        </div>
    );
};

export default Captcha;
