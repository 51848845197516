import React, { useState, useEffect } from 'react';
import './basics.css';

const BasicsPage = () => {
    const [question, setQuestion] = useState('');
    const [studentAnswer, setStudentAnswer] = useState('');
    const [feedback, setFeedback] = useState('');
    const [fireworks, setFireworks] = useState(false);

    useEffect(() => {
        fetchQuestion();
    }, []);

    const fetchQuestion = async () => {
        const response = await fetch('API_ENDPOINT_FOR_QUESTION');
        const data = await response.json();
        setQuestion(data.question);
    };

    const checkAnswer = async () => {
        const response = await fetch('API_ENDPOINT_FOR_ANSWER_CHECK', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ answer: studentAnswer }),
        });
        const result = await response.json();
        if (result.correct) {
            setFireworks(true);
            setFeedback('回答正确！');
        } else {
            setFireworks(false);
            setFeedback('回答错误，请再试一次。');
        }
    };

    const solveQuestion = async () => {
        const response = await fetch('API_ENDPOINT_FOR_QUESTION_SOLVE', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ question }),
        });
        const result = await response.json();
        setFeedback(`正确答案是：${result.solution}`);
    };

    const handlePreviousQuestion = () => {
        // 处理上一题逻辑
    };

    const handleNextQuestion = () => {
        // 处理下一题逻辑
    };

    return (
        <div className="basics-page">
            <h1>基础知识</h1>
            <div className="interaction">
                <div className="question">
                    <h2>题目：</h2>
                    <p>{question}</p>
                </div>
                <div className="answer">
                    <textarea
                        value={studentAnswer}
                        onChange={(e) => setStudentAnswer(e.target.value)}
                        placeholder="请输入你的答案"
                    ></textarea>
                    <div className="buttons">
                        <button onClick={checkAnswer}>提交答案</button>
                        <button onClick={solveQuestion}>解答</button>
                    </div>
                </div>
                <div className="feedback">
                    <p>{feedback}</p>
                </div>
                <div className="navigation">
                    <button onClick={handlePreviousQuestion}>上一题</button>
                    <button onClick={handleNextQuestion}>下一题</button>
                </div>
            </div>
            {fireworks && <div className="fireworks">🎆🎆🎆</div>}
        </div>
    );
};

export default BasicsPage;
