import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Input, Button, Row, Col, message, Spin, Modal, Tooltip, Menu } from 'antd';  // 确保导入 Menu
import { SendOutlined, StopOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TipNotification from './TipNotification';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import './ChatPage.css';

const parseChatHistory = (historyString) => {
    return historyString.split('\n').map(entry => JSON.parse(entry));
};

const useChatHistory = (isLoggedIn, userName, handleLogoutClick) => {
    const [chatHistory, setChatHistory] = useState([]);
    const [loadingHistory, setLoadingHistory] = useState(true);

    useEffect(() => {
        if (!isLoggedIn) {
            setLoadingHistory(false);
            return;
        }

        const token = sessionStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        setLoadingHistory(true);

        fetch(`${apiUrl}/chat_history`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ user_name: userName }),
        })
            .then(response => {
                if (response.status === 401) {
                    message.error('登录状态已失效，请重新登录');
                    handleLogoutClick();
                    return;
                }
                return response.json();
            })
            .then(data => {
                setLoadingHistory(false);
                if (data && data.status === 200) {
                    const historyString = data.chat_history;
                    const parsedHistory = parseChatHistory(historyString);
                    setChatHistory(parsedHistory);
                } else if (data && data.status === 204) {
                    // 如果状态码为204，不进行任何处理
                } else if (data) {
                    message.error(data.message);
                }
            })
            .catch(() => {
                message.error('加载聊天历史失败');
                setLoadingHistory(false);
            });

    }, [isLoggedIn, userName, handleLogoutClick]);

    return { chatHistory, setChatHistory, loadingHistory };
};

const ChatPage = forwardRef(({ isLoggedIn, userName, apiKey, handleLogoutClick }, ref) => {
    const [messageText, setMessageText] = useState('');
    const [loading, setLoading] = useState(false);
    const [controller, setController] = useState(null);
    const [showTip, setShowTip] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const chatDisplayRef = useRef(null);
    const { chatHistory, setChatHistory, loadingHistory } = useChatHistory(isLoggedIn, userName, handleLogoutClick);

    useImperativeHandle(ref, () => ({
        clearChatHistory() {
            setChatHistory([]);
        }
    }));

    const handleScrollToBottom = () => {
        if (chatDisplayRef.current) {
            chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
        }
        setShowTip(false);
    };

    useEffect(() => {
        handleScrollToBottom();
    }, [chatHistory]);

    const handleSendMessage = () => {
        if (!messageText.trim()) {
            message.error('发送内容不能为空');
            return;
        }

        if (!isLoggedIn || !apiKey) {
            message.error('登录状态已失效，请重新登录');
            return;
        }

        const userMessage = { role: 'user', content: messageText };
        setChatHistory(prevChatHistory => [...prevChatHistory, userMessage]);
        setMessageText('');

        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const newController = new AbortController();
        setController(newController);
        setLoading(true);

        // 将聊天框内的所有内容作为消息的一部分发送
        const allMessages = [...chatHistory, userMessage];
        const token = sessionStorage.getItem('token');

        fetch(`${apiUrl}/chat`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_name: userName,
                messages: JSON.stringify(allMessages),
                api_key: apiKey,
            }),
            signal: newController.signal,
        })
            .then(response => {
                if (response.status === 401) {
                    handleLogoutClick();
                    return;
                }
                return response.json();
            })
            .then(data => {
                setLoading(false);
                setController(null);
                if (data && data.status === 200) {
                    const aiMessage = { role: 'assistant', content: data.message };
                    setChatHistory(prevChatHistory => [...prevChatHistory, aiMessage]);
                    setShowTip(true);
                } else if (data) {
                    message.error(data.message);
                }
                handleScrollToBottom();
            })
            .catch(error => {
                if (error.name === 'AbortError') {
                    console.log('请求被取消');
                } else {
                    message.error('发送消息失败');
                }
                setLoading(false);
                setController(null);
            });
    };

    const handleStopRequest = () => {
        if (controller) {
            controller.abort();
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!loading) {
                handleSendMessage();
            }
        }
    };

    const handleClearChatHistory = () => {
        if (!isLoggedIn || !apiKey) {
            message.error('登录状态已失效，请重新登录');
            return;
        }

        Modal.confirm({
            title: '确认删除',
            content: '您将删除所有会话记录，确认删除？',
            okText: '确认',
            cancelText: '取消',
            open: isModalOpen,
            onOk: () => {
                const token = sessionStorage.getItem('token');
                const apiUrl = process.env.REACT_APP_API_BASE_URL;
                setLoading(true);

                fetch(`${apiUrl}/delete_chat_history`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ user_name: userName }),
                })
                    .then(response => {
                        if (response.status === 401) {
                            handleLogoutClick();
                            return;
                        }
                        return response.json();
                    })
                    .then(data => {
                        setLoading(false);
                        if (data && data.status === 200) {
                            setChatHistory([]);
                            message.success('聊天记录已清空');
                        } else if (data) {
                            message.error(data.message);
                        }
                    })
                    .catch(() => {
                        message.error('清空聊天记录失败');
                        setLoading(false);
                    });
            },
        });
    };

    const menuItems = [
        // 其他菜单项
    ];

    return (
        <div className="chat-container">
            {isLoggedIn && loadingHistory && (
                <div className="loading-overlay">
                    <Spin size="large" />
                </div>
            )}
            {isLoggedIn && showTip && <TipNotification message="有新回复，请查看！" onClick={handleScrollToBottom} />}
            <Menu items={menuItems} />
            <div className="chat-content">
                <div className="chat-display" ref={chatDisplayRef}>
                    {chatHistory.length === 0 ? (
                        <div className="no-chat-message">暂无聊天记录</div>
                    ) : (
                        chatHistory.map((message, index) => (
                            <Message key={index} message={message} />
                        ))
                    )}
                    {loading && (
                        <div style={{ position: 'relative', height: '100px' }}>
                            <Spin size="large" />
                        </div>
                    )}
                    {chatHistory.length > 0 && (
                        <Tooltip title="清空聊天记录">
                            <Button
                                type="danger"
                                icon={<DeleteOutlined />}
                                className="clear-button"
                                onClick={handleClearChatHistory}
                                disabled={loading || !isLoggedIn || !apiKey}
                            />
                        </Tooltip>
                    )}
                </div>
                <ChatInput
                    messageText={messageText}
                    setMessageText={setMessageText}
                    handleKeyPress={handleKeyPress}
                    handleSendMessage={handleSendMessage}
                    handleStopRequest={handleStopRequest}
                    handleClearChatHistory={handleClearChatHistory}
                    loading={loading}
                    controller={controller}
                    isLoggedIn={isLoggedIn}
                    apiKey={apiKey}
                />
            </div>
        </div>
    );
});

const Message = ({ message }) => (
    <div className={`message-container ${message.role}-container`}>
        {message.role === 'user' ? (
            <div className="user-message">
                <p>{message.content}</p>
            </div>
        ) : (
            <div className="ai-message">
                <ReactMarkdown
                    components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            return !inline && match ? (
                                <div className="code-block">
                                    <div className="code-header">
                                        <span className="language">{match[1]}</span>
                                        <CopyToClipboard text={String(children).replace(/\n$/, '')}>
                                            <Button
                                                icon={<CopyOutlined />}
                                                size="small"
                                                className="copy-button"
                                            >
                                                复制
                                            </Button>
                                        </CopyToClipboard>
                                    </div>
                                    <SyntaxHighlighter language={match[1]} style={vs2015} className={className} {...props}>
                                        {String(children).replace(/\n$/, '')}
                                    </SyntaxHighlighter>
                                </div>
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            );
                        }
                    }}
                >
                    {message.content}
                </ReactMarkdown>
            </div>
        )}
    </div>
);

const ChatInput = ({
    messageText,
    setMessageText,
    handleKeyPress,
    handleSendMessage,
    handleStopRequest,
    handleClearChatHistory,
    loading,
    controller,
    isLoggedIn,
    apiKey,
}) => (
    <Row className="chat-input-row">
        <Col span={24} className="input-col">
            <Input.TextArea
                rows={4}
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                onKeyDown={handleKeyPress}
                style={{ resize: 'none' }}
                disabled={loading && !controller}
            />
            <Button
                type="primary"
                icon={loading ? <StopOutlined /> : <SendOutlined />}
                className="send-button"
                onClick={loading ? handleStopRequest : handleSendMessage}
                disabled={(loading && !controller) || !isLoggedIn || !apiKey || !messageText.trim()}
            />
            <Tooltip title="清空聊天记录">
                <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    className="clear-button"
                    onClick={handleClearChatHistory}
                    disabled={loading || !isLoggedIn || !apiKey}
                />
            </Tooltip>
        </Col>
    </Row>
);

export default ChatPage;
