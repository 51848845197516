import React, { useState, useEffect, useRef } from 'react';
import { Layout, Menu, Button, message } from 'antd';
import {
    MessageTwoTone,
    SettingTwoTone,
    LeftSquareTwoTone,
    RightSquareTwoTone,
    UserOutlined,
    LogoutOutlined,
    OpenAIFilled,
    BookTwoTone,
    FileTextOutlined,
    ReadOutlined,
    RocketOutlined,
    ExperimentOutlined
} from '@ant-design/icons';
import ChatPage from './components/ChatPage';
import SettingsPage from './components/SettingsPage';
import AuthPage from './components/AuthPage';
import ExplainPage from './Python_Study/explain';
import BasicsPage from './Python_Study/basics';
import UpgradePage from './Python_Study/upgrade';
import ApplyPage from './Python_Study/apply';
import ExperimentPage from './Python_Study/experiment';
import './App.css';

const { Header, Sider, Content } = Layout;

function App() {
    const [collapsed, setCollapsed] = useState(() => {
        const savedCollapsedState = localStorage.getItem('collapsed');
        return savedCollapsedState ? JSON.parse(savedCollapsedState) : false;
    });
    const [currentMenu, setCurrentMenu] = useState('explain');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const chatPageRef = useRef();
    const authPageRef = useRef();

    useEffect(() => {
        let isMounted = true;
        document.title = "Study";
        const loggedInStatus = localStorage.getItem('isLoggedIn');
        const userName = localStorage.getItem('user_name');
        if (loggedInStatus === 'true' && userName) {
            setIsLoggedIn(true);
            if (isMounted) {
                handleSearchKey(userName);
            }
        }
        return () => {
            isMounted = false;
        };
    }, []);

    const toggleCollapsed = () => {
        setCollapsed(prevCollapsed => {
            const newCollapsed = !prevCollapsed;
            localStorage.setItem('collapsed', JSON.stringify(newCollapsed));
            return newCollapsed;
        });
    };

    const handleMenuClick = e => {
        if (!isLoggedIn && e.key !== 'explain') {
            message.error('请先登录');
            return;
        }
        if (e.key === 'header') {
            window.location.reload();
        } else if (e.key !== 'toggle') {
            setCurrentMenu(e.key);
        }
    };

    const handleLoginClick = () => {
        setShowAuthModal(true);
    };

    const handleLogoutClick = () => {
        setIsLoggedIn(false);
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('user_name');
        sessionStorage.removeItem('token'); // 清除 token
        setApiKey('');
        if (chatPageRef.current) {
            chatPageRef.current.clearChatHistory();
        }
        if (authPageRef.current) {
            authPageRef.current.resetForm();
        }
        setCurrentMenu('explain');
    };

    const handleAuthCancel = () => {
        setShowAuthModal(false);
    };

    const handleLoginSubmit = async (values) => {
        setIsLoggedIn(true);
        setShowAuthModal(false);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('user_name', values.user_name);
        await handleSearchKey(values.user_name);
    };

    const handleRegisterSubmit = (values) => {
    };

    const handleSearchKey = async (userName) => {
        if (!userName) {
            message.error('登录已失效，请重新登录！');
            handleLogoutClick();
            return;
        }

        const token = sessionStorage.getItem('token');
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const url = `${apiUrl}/search_key`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_name: userName }),
            });

            if (response.status === 401) {
                message.error(data.message || '登录已失效，请重新登录');
                handleLogoutClick();
                return;
            }

            const data = await response.json();

            if (response.ok && data.status === 200) {
                setApiKey(data.api_key);
            } else if (response.ok && data.status === 202) {
                // No API key found, handle accordingly if needed
            } else {
                message.error(data.message);
            }
        } catch (error) {
         //   message.error('网络异常，检查后重试！');
        }
    };

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ backgroundColor: '#fff' }}>
                <div className="logo" />
                <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={[currentMenu]}
                    selectedKeys={[currentMenu]}
                    onClick={handleMenuClick}
                >
                    <Menu.Item key="header" icon={<OpenAIFilled />} title="Study To Python" style={{ fontSize: '16px' }}>
                        Study To Python
                    </Menu.Item>
                    <Menu.Item key="explain" icon={<FileTextOutlined twoToneColor="#52c41a" />} title="说明">
                        说明
                    </Menu.Item>
                    <Menu.Item key="chat" icon={<MessageTwoTone twoToneColor="#52c41a" />} title="Ask To Ai">
                        Ask To Ai
                    </Menu.Item>
                    <Menu.SubMenu key="python_study" title="Python 学习" icon={<BookTwoTone />}>
                        <Menu.Item key="basics" icon={<ReadOutlined />}>基础知识</Menu.Item>
                        <Menu.Item key="upgrade" icon={<RocketOutlined />}>进阶技能</Menu.Item>
                        <Menu.Item key="apply" icon={<FileTextOutlined />}>应用领域</Menu.Item>
                        <Menu.Item key="experiment" icon={<ExperimentOutlined />}>实战项目</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key="settings" icon={<SettingTwoTone />} title="设置">
                        设置
                    </Menu.Item>
                </Menu>
                <div className="toggle-button" onClick={toggleCollapsed}>
                    <Menu theme="light" mode="inline">
                        <Menu.Item key="toggle"
                            icon={collapsed ? <RightSquareTwoTone /> : <LeftSquareTwoTone />}
                            title={collapsed ? '展开侧边栏' : '收起侧边栏'}>
                            {collapsed ? '' : '收起侧边栏'}
                        </Menu.Item>
                    </Menu>
                </div>
            </Sider>
            <Layout>
                <Header className="header-custom" style={{
                    padding: 0,
                    backgroundColor: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingRight: '16px'
                }}>
                    {isLoggedIn ? (
                        <div className="login-container">
                            <p className="login-prompt"></p> {/* 空的占位符，用于居中 */}
                            <div className="auth-buttons">
                                <Button icon={<UserOutlined />} type="text">{/* 显示账号 */}</Button>
                                <Button icon={<LogoutOutlined />} type="text" onClick={handleLogoutClick}>退出</Button>
                            </div>
                        </div>
                    ) : (
                        <div className="login-container">
                            <Button type="primary" onClick={handleLoginClick}>登录</Button>
                        </div>
                    )}
                </Header>
                <Content style={{ margin: '24px 16px', padding: '0 16px 24px', background: '#fff', minHeight: 280 }}>
                    {currentMenu === 'explain' && <ExplainPage />}
                    {isLoggedIn && currentMenu === 'chat' &&
                        <ChatPage ref={chatPageRef} isLoggedIn={isLoggedIn} userName={localStorage.getItem('user_name')}
                            apiKey={apiKey} handleLogoutClick={handleLogoutClick} />}
                    {isLoggedIn && currentMenu === 'settings' &&
                        <SettingsPage isLoggedIn={isLoggedIn} apiKey={apiKey} setApiKey={setApiKey}
                            userName={localStorage.getItem('user_name')} />}
                    {isLoggedIn && currentMenu === 'basics' && <BasicsPage />}
                    {isLoggedIn && currentMenu === 'upgrade' && <UpgradePage />}
                    {isLoggedIn && currentMenu === 'apply' && <ApplyPage />}
                    {isLoggedIn && currentMenu === 'experiment' && <ExperimentPage />}
                </Content>
            </Layout>
            <AuthPage
                ref={authPageRef}
                open={showAuthModal}
                onCancel={handleAuthCancel}
                onLoginSubmit={handleLoginSubmit}
                onRegisterSubmit={handleRegisterSubmit}
                onSearchKey={handleSearchKey}
            />
        </Layout>
    );
}

export default App;
