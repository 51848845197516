import React from 'react';
import './explain.css';

const ExplainPage = () => {
    return (
        <div className="explain-page">
            <h1>说明</h1>
            <p>这是 Python 学习的说明页面。</p>
        </div>
    );
};

export default ExplainPage;
