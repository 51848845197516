// AuthPage.js文件
import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import Captcha from './Captcha';
import './AuthPage.css';

const generateRandomCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 4; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
};

const AuthPage = forwardRef(({ open, onCancel, onLoginSubmit, onRegisterSubmit, onSearchKey }, ref) => {
    const [isLogin, setIsLogin] = useState(true);
    const [form] = Form.useForm();
    const [sendingCode, setSendingCode] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [captchaInput, setCaptchaInput] = useState('');
    const [captchaVisible, setCaptchaVisible] = useState(false);
    const [captchaPosition, setCaptchaPosition] = useState({ top: 0, left: 0 });
    const sendCodeButtonRef = useRef(null);
    const [countdown, setCountdown] = useState(0);

    useImperativeHandle(ref, () => ({
        resetForm() {
            form.resetFields();
        }
    }));

    useEffect(() => {
        const updateCaptchaPosition = () => {
            if (captchaVisible && sendCodeButtonRef.current) {
                const rect = sendCodeButtonRef.current.getBoundingClientRect();
                setCaptchaPosition({
                    top: rect.bottom + window.scrollY,
                    left: rect.left + window.scrollX
                });
            }
        };

        updateCaptchaPosition();
        window.addEventListener('resize', updateCaptchaPosition);

        // 监听点击事件，点击验证码窗口外部时关闭验证码窗口
        const handleClickOutside = (event) => {
            if (captchaVisible && !sendCodeButtonRef.current.contains(event.target)) {
                setCaptchaVisible(false);
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('resize', updateCaptchaPosition);
            document.removeEventListener('click', handleClickOutside);
        };
    }, [captchaVisible]);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleSwitch = () => {
        setIsLogin(!isLogin);
    };

    const handleCaptchaInputChange = (e) => {
        const value = e.target.value;
        setCaptchaInput(value);
        if (value.length === 4) {
            if (value !== captcha) {
                message.error('验证码错误');
                refreshCaptcha();  // 自动刷新验证码
                setCaptchaInput('');  // 清空输入框
            } else {
                setCaptchaVisible(false);
                handleSendCode();
            }
        }
    };

    const handleSendCode = async () => {
        try {
            const phoneNumber = form.getFieldValue('user_phone');
            if (!phoneNumber) {
                message.error('请输入手机号');
                return;
            }
            setSendingCode(true);
            // 假设验证码发送成功
            setTimeout(() => {
                const generatedCode = 'AB12'; // 生成验证码
                setVerificationCode(generatedCode);
                message.success(`验证码已发送`);
                setSendingCode(false);
                setCountdown(2);
            }, 1000);
        } catch (error) {
            message.error('验证码发送失败');
            setSendingCode(false);
        }
    };

    const handleSubmit = async (values) => {
        if (!isLogin) {
            const enteredCode = form.getFieldValue('verification_code');
            if (enteredCode !== verificationCode) {
                message.error('验证码错误');
                return;
            }
        }

        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const url = isLogin ? `${apiUrl}/login` : `${apiUrl}/register`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            const data = await response.json();

            if (response.ok && data.status === 200) {
                message.success(data.message);
                if (isLogin) {
                    sessionStorage.setItem('token', data.token); // 存储 token
                    onLoginSubmit(values);
                    await onSearchKey(values.user_name);
                } else {
                    onRegisterSubmit(values);
                }
            } else {
                message.error(data.message);
            }
        } catch (error) {
            message.error('网络异常，检查后重试！');
        }
    };

    const handleNumberInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };

    const handlePasswordInput = (e) => {
        e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '');
    };

    const handleCaptchaVisible = () => {
        setCaptchaVisible(true);
    };

    const refreshCaptcha = () => {
        setCaptcha(generateRandomCode());
    };

    return (
        <>
            <Modal
                title={<div style={{ textAlign: 'center' }}>{isLogin ? '登录' : '注册'}</div>}
                open={open}
                onCancel={onCancel}
                footer={null}
                centered
                maskClosable={false}  // 禁止点击遮罩关闭
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="user_name"
                        rules={[
                            { required: true, message: '请输入用户名' },
                            { pattern: /^\d{10}$/, message: '用户名至少 10 个数字' },
                        ]}
                    >
                        <Input
                            placeholder="用户名"
                            maxLength={10}
                            onInput={handleNumberInput}
                        />
                    </Form.Item>
                    {!isLogin && (
                        <>
                            <Form.Item
                                name="user_phone"
                                rules={[
                                    { required: true, message: '请输入手机号' },
                                    { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' },
                                ]}
                            >
                                <Input
                                    placeholder="手机号"
                                    maxLength={11}
                                    onInput={handleNumberInput}
                                    addonAfter={
                                        <div
                                            className="send-code-button"
                                            ref={sendCodeButtonRef}
                                            onClick={handleCaptchaVisible}
                                            style={{ pointerEvents: countdown > 0 ? 'none' : 'auto', color: countdown > 0 ? 'grey' : 'black' }}
                                        >
                                            {countdown > 0 ? `${countdown}s` : '发送'}
                                        </div>
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="verification_code"
                                rules={[{ required: true, message: '请输入验证码' }]}
                            >
                                <Input
                                    placeholder="验证码"
                                    maxLength={6}
                                    onInput={(e) => e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '')}
                                />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item
                        name="user_password"
                        rules={[
                            { required: true, message: '请输入密码' },
                            { pattern: /^[A-Za-z0-9]{6,18}$/, message: '密码只能包含大小写字母和数字，长度为6到18个字符' },
                        ]}
                    >
                        <Input.Password
                            placeholder="密码"
                            maxLength={18}
                            onInput={handlePasswordInput}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            {isLogin ? '登录' : '注册'}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button type="link" onClick={handleSwitch} style={{ float: 'right' }}>
                            {isLogin ? '立即注册' : '立即登录'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            {captchaVisible && (
                <div
                    className="captcha-container"
                    style={{ top: `${captchaPosition.top}px`, left: `${captchaPosition.left}px`, position: 'absolute' }}
                    onClick={e => e.stopPropagation()}
                >
                    <div className="captcha-content">
                        <Captcha onChange={(code) => setCaptcha(code)} />
                        <Input
                            placeholder="验证码"
                            maxLength={4}
                            value={captchaInput}
                            onInput={(e) => e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, '')}
                            onChange={handleCaptchaInputChange}
                            style={{ width: '100px' }}  // 确保输入框宽度与图片一致
                        />
                    </div>
                </div>
            )}
        </>
    );
});

export default AuthPage;
