import React from 'react';
import './experiment.css';

const ExperimentPage = () => {
    return (
        <div className="experiment-page">
            <h1>实战项目</h1>
            <p>这是 Python 学习的实战项目页面。</p>
        </div>
    );
};

export default ExperimentPage;
